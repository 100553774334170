<template>
  <div>
    <v-card class="mx-auto pa-5 mt-16" elevation="3" outlined max-width="450px">
      <v-card-text align="center">
        <p class="display-1 text--primary">Register</p>
        <p>Selamat Datang, Silahkan Register</p>
      </v-card-text>

      <form align="center">
        <v-text-field
          dense
          outlined
          v-model="email"
          label="E-mail"
          type="email"
          required
        ></v-text-field>
        <v-text-field
          dense
          outlined
          v-model="password"
          label="Password"
          required
          type="text"
        ></v-text-field>
        <v-btn @click="submit">Register</v-btn>
        <v-card-subtitle>
          Sudah punya akun? <a v-on:click="register"> Sign In </a>
        </v-card-subtitle>
      </form>
    </v-card>
  </div>
</template>

<script>
// import firebase from "firebase/compat";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const auth = getAuth();

export default {
  name: "Login",
  data: () => ({
    // error: "",
    load: false,
    snackbar: false,
    error_message: "",
    color: "",
    valid: false,
    email: "",
    password: "",
    passwordRules: [(v) => !!v || "Password tidak boleh kosong :("],
    emailRules: [(v) => !!v || "E-mail tidak boleh kosong :("],
  }),
  computed: {},

  methods: {
    register() {
      this.$router.push("/login");
    },
    submit() {
      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          // .then((userCredential) => {
          // Signed in
          // const user = userCredential.user;
          // console.log(user);
          // ...
          this.$router.push("/login");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
          // ..
        });

      // firebase
      //   .auth()
      //   .createUserWithEmailAndPassword(this.email, this.password)
      //   .then((userCredential) => {
      //     // Signed in
      //     // console.log(userCredential);
      //     this.$router.push("/dashboard");

      //     // const user = userCredential.user;
      //     // ...
      //   })
      //   .catch((error) => {
      //     const errorCode = error.code;
      //     const errorMessage = error.message;
      //     // console.log("code", errorCode);
      //     // console.log("message", errorMessage);
      //     // ..
      //   });
    },
    clear() {
      this.$refs.form.reset(); //Clear form login
    },
  },

  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
    });
  },
};
</script>
